import type { Lease, LeaseAccount, LeaseAccountResponse } from '~/repository/modules'
import type { ComboboxOption } from '~/ui/components/Combobox/BaseCombobox.vue'

function useLeaseAccountsComposable(leaseId: Lease['id']) {
  const { $api } = useNuxtApp()

  const loading = ref<boolean>(false)
  const accounts = ref<LeaseAccountResponse>({
    lease_accounts: [],
    computed: {},
  })

  const list = () => {
    loading.value = true
    $api.leases.accounts
      .list(leaseId)
      .then((response) => {
        accounts.value = response.data
      })
      .finally(() => {
        loading.value = false
      })
  }

  list()

  $api.leases.transactions.on(['feeCreated', 'paymentCreated'], () => list())

  const accountsSelectList = computed(() => {
    return useMap(accounts.value.lease_accounts, (account: LeaseAccount) => {
      return {
        label: `[${account.account}] - ${account.description}`,
        value: account.id,
      } as ComboboxOption
    })
  })

  return {
    loading,
    accounts,
    accountsSelectList,
    list,
  }
}

export const useLeaseAccounts = createSharedComposable(
  useLeaseAccountsComposable,
)
